import React, { useEffect, useState } from "react";
import {
  EquipmentApi,
  EquipmentPreviewApi,
} from "../../../../Services/ContactChildService";
import classes from "../../Contact.module.css";
import Top from "../../../../utils/Top/Top";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Row, Container, Col } from "react-bootstrap";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { useSelector } from "react-redux";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useLocation, NavLink } from "react-router-dom";
import { PageTitle } from "../../../../utils/common";

const Equipment = () => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [equipment, setEquipment] = useState(null);
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-6">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-7">
      {children}
    </h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Link
            to={{
              pathname: "/",
            }}
          >
            {children}
          </Link>
        );
      },
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }

  useEffect(async () => {
    let equipmentData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      equipmentData = await EquipmentPreviewApi(url);
    } else {
      equipmentData = await EquipmentApi(url);
    }
    setEquipment(equipmentData);
    PageTitle(equipmentData?.Title ?? "");
  }, []);

  let display = null,
    path = [];
  if (equipment) {
    display = equipment?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = equipment?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }
  return (
    <>
      {equipment?.PageImages?.length > 0 && (
        <Top
          pageTitle={equipment?.PageTitle}
          images={equipment?.PageImages}
          title={equipment?.Title}
        />
      )}

      <section className={" text-left content5 centeradd cid-szWdSGiEXu"}>
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={equipment?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = equipment?.Path.substring(
                      0,
                      equipment?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {equipment?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {equipment?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>
          <Row className="mb-5">
            {equipment?.SubMenu?.map((info, id) => {
              return (
                <Col md={6} lg={4}>
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        // image={"https:" + info.fields.image.fields.file.url}
                        image={"https:" +info?.fields?.image?.fields?.file?.url}
                      />
                      <CardContent sx={{ minHeight: 150 }}>
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          className="card-title text-center"
                        >
                          {info.fields.title}
                        </Typography>
                        <Typography className="mbr-black mbr-fonts-style mbr-text mb-3 display-7 card-desc">
                          {info.fields.description?.content?.map((info, id) => {
                            return documentToReactComponents(info, options);
                          })}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Equipment;
