import React, { useEffect, useState } from "react";
import {
  NorwayApi,
  NorwayPreviewApi,
} from "../../../../Services/OurLocationChildService";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import classes from "../../OurLocations.module.css";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
import TopNew from "../../../../utils/Top/Top-new";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useLocation, Link, NavLink } from "react-router-dom";
import { PageTitle } from "../../../../utils/common";

const Norway = () => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [expanded, setExpanded] = React.useState("address1");
  const handleChange = (address) => (event, newExpanded) => {
    setExpanded(newExpanded ? address : false);
  };
  const [norway, setNorway] = useState(null);
  useEffect(async () => {
    let norwayData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      norwayData = await NorwayPreviewApi(url);
    } else {
      norwayData = await NorwayApi(url);
    }
    PageTitle(norwayData?.Title ?? "");
    setNorway(norwayData);
  }, []);
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title text-center mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-6">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-7">
      {children}
    </h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.target?.fields?.file?.url}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.uri}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }
  const styles = {
    media1: {
      height: 275,
      width: "100%",
      objectFit: "contain",
    },
    media2: {
      height: 275,
      width: "100%",
    },
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));
  const styles1 = {
    media1: {
      height: 275,
      width: "100%",
      objectFit: "contain",
    },
    media2: {
      height: 275,
      width: "100%",
    },
  };

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  let display = null,
    path = [];

  if (norway) {
    display = norway?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = norway?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }

  return (
    <>
      {/* <Slider data={norway?.PageImages} title={norway?.Title}></Slider> */}
      {norway?.PageImages?.length > 0 && (
        <TopNew
          pageTitle={norway?.PageTitle}
          images={norway?.PageImages}
          title={norway?.Title}
        />
      )}
      <section
        className={
          classes.sectionContent +
          " text-left content5 centeradd cid-szWdSGiEXu"
        }
      >
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={norway?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = norway?.Path.substring(
                      0,
                      norway?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {norway?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {norway?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>
          {norway?.links && (
            <Row className="mb-5">
              {norway?.links?.map((info, id) => {
                return (
                  <Col md={6} lg={4}>
                    <Card className="text-center">
                      <CardActionArea>
                        <CardMedia
                          style={id ? styles1.media2 : styles1.media1}
                          component="img"
                          // sx={{ minHeight: 300 }}
                          image={"https:" + info.fields.image.fields.file.url}
                        />
                        <CardContent sx={{ minHeight: 150 }}>
                          <Typography
                            gutterBottom
                            variant="h4"
                            component="div"
                            className="card-title"
                          >
                            {info.fields.title}
                          </Typography>
                          <Typography className="mbr-black mbr-fonts-style mbr-text mb-3 display-7 text-center card-desc">
                            {info.fields.description?.content?.map(
                              (info, id) => {
                                return documentToReactComponents(info);
                              }
                            )}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}

          {/* <Row>
            <Col md={12}>
              {norway?.SubMenu?.map((info, id) => {
                return (
                  <Accordion
                    expanded={expanded === info.fields.title}
                    onChange={handleChange(info.fields.title)}
                  >
                    <AccordionSummary id={info.fields.title + "d-header"}>
                      <Typography>{info.fields.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Row>
                        <Col>
                          {info.fields?.description?.content?.map(
                            (info, id) => {
                              return documentToReactComponents(info, options);
                            }
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        {info.fields.pageImages?.map((info1, id2) => {
                          return (
                            <Col md={6} lg={4}>
                              <Card className="text-center">
                                <CardActionArea>
                                  <CardMedia
                                    component="img"
                                    image={info1.fields.file.url}
                                  />
                                  <CardContent sx={{ minHeight: 150 }}>
                                    <Typography
                                      gutterBottom
                                      variant="h4"
                                      component="div"
                                      className="card-title"
                                    >
                                      {info1.fields.title}
                                    </Typography>
                                    <Typography className="mbr-black mbr-fonts-style mbr-text mb-3 display-7 text-center card-desc">
                                      {info1.fields.description}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Col>
          </Row> */}
        </Container>
      </section>
    </>
  );
};

export default Norway;
