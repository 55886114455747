import React, { useEffect, useState } from "react";
import classes from "./OurLocations.module.css";
import TopNew from "../../utils/Top/Top-new";
import { LocationApi, LocationPreviewApi } from "../../Services/OurLocation";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link, NavLink, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../assets/mobirise/css/mbr-additional.css";
import "../../assets/theme/css/style.css";
import Slider from "../../Components/Slider/Slider";
import { PageTitle } from "../../utils/common";

const OurLocations = () => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [ourLocation, setOurLocation] = useState(null);
  useEffect(async () => {
    let locationData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      locationData = await LocationPreviewApi(url);
    } else {
      locationData = await LocationApi(url);
    }
    PageTitle(locationData?.Title);
    setOurLocation(locationData);
  }, []);

  const styles = {
    media1: {
      height: 275,
      width: "100%",
      objectFit: "contain",
    },
    media2: {
      height: 275,
      width: "100%",
    },
  };

  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title text-center mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-5">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 display-7">{children}</h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }
  let display = null,
    path = [];

  if (ourLocation) {
    display = ourLocation?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = ourLocation?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }
  let displayLinks = null;
  if (ourLocation?.links) {
    console.log(ourLocation?.links);
    displayLinks = ourLocation?.links?.map((info, id) => {
      // if(typeOf ourLocation?.links ==="object")
      return (
        <Col md={6} lg={4}>
          <Card className="text-center mb-5">
            <CardActionArea>
              <CardMedia
                component="img"
                style={id ? styles.media2 : styles.media1}
                image={"https:" + info?.fields?.image?.fields?.file?.url}
              />
              <CardContent sx={{ minHeight: 150 }}>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  className="card-title"
                >
                  {info.fields.title}
                </Typography>
                <Typography className="mbr-black mbr-fonts-style mbr-text mb-3 display-7 text-center card-desc">
                  {info.fields.description?.content?.map((info, id) => {
                    return documentToReactComponents(info);
                  })}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className="justify-content-center">
              <Link
                to={{
                  pathname: "/locations/" + info?.fields?.slug,
                }}
                className="btn btn-primary display-4"
              >
                Learn more
              </Link>
            </CardActions>
          </Card>
        </Col>
      );
    });
  }
  return (
    <>
      {ourLocation?.PageImages?.length > 0 && (
        <TopNew
          pageTitle={ourLocation?.PageTitle}
          images={ourLocation?.PageImages}
          title={ourLocation?.Title}
        />
      )}
      <section
        className={
          classes.sectionContent +
          " text-left content5 centeradd cid-szWdSGiEXu"
        }
      >
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={ourLocation?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    return (
                      <>
                        <NavLink to={"/" + m}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {ourLocation?.Title}
                </div>
              </div>
              {display}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="features3 cid-szWdJmjpxZ">
        <Container>
          <Row className="text-center">
            <Col md={12}>
              <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-3 display-5">
                Our Locations
              </h1>
            </Col>
          </Row>
          <Row className="mt-4">{displayLinks}</Row>
        </Container>
      </section>
    </>
  );
};

export default OurLocations;
