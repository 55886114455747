import axios from "axios";

export const SghTrainingApi = async (url) => {
  let response = await axios.get(url + "/getPage/Services/SghTraining");
  return response.data;
};
export const SghTrainingPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/Services/SghTraining");
  return response.data;
};

export const TrainingCoursesApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/Services/SghTraining/TrainingCourses"
  );
  return response.data;
};
export const TrainingCoursesPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/Services/SghTraining/TrainingCourses"
  );
  return response.data;
};


export const postApi = async (
  url,
  courseName,
  courseLocation,
  price,
  start,
  end,
  firstName,
  lastName,
  email,
  company,
  streetAddress,
  postalCode,
  city,
  country,
  telephone,
  fax,
  billingCompany,
  attention,
  billingStreetAddress,
  billingPostalCode,
  billingCity,
  billingCountry,
  euVatNo,
  addtionalInformation
) => {
  let arrdata = {
    CourseName: courseName,
    Location: courseLocation,
    Price: price,
    StartDate: start,
    EndDate: end,
    FirstName: firstName,
    LastName: lastName,
    Email: email,
    Company: company,
    StreetAddress: streetAddress,
    PostalCode: postalCode,
    City: city,
    Country: country,
    Telephone: telephone,
    Fax: fax,
    BillingCompany: billingCompany,
    Attention: attention,
    BillingStreetAddress: billingStreetAddress,
    BillingPostalCode: billingPostalCode,
    BillingCity: billingCity,
    BillingCountry: billingCountry,
    EuVatNo: euVatNo,
    AdditionalInformation: addtionalInformation,
  };

  let response = await axios.post(url + "/getPage/postFormApi", arrdata, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  });

  return response.data;
};
