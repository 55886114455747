import React from "react";
import classes from "./TopSection.module.css";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const TopNew = (props) => {
  let images = null;

  if (props?.images) {
    images = props?.images.map((item, key) => {
      if (key == 0) {
        return (
          <img
            src={"https:" + item?.fields?.file?.url}
            className={
              props?.images?.length > 0 ? classes.displayimage : "d-none"
            }
          ></img>
        );
      }
    });
  }

  return (
    <div>
      <div className={classes.top}>
        <div className={classes.back}></div>

        <Row className={classes.imagenew + " mx-0"}>
          <Col xs="12" md="10" className={classes.imgcol + " p-0"}>
            {images}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(TopNew);
