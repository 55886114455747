import axios from "axios";

export const InternalApi = async (url, slug,mainmenu) => {
  console.log(url);
  var change_slug = slug;
  if(slug==="OpeningHours"){
    change_slug = mainmenu.toLowerCase()+"-"+"opening-hours";
    
  }else if(slug==="OurCustomers"){
    change_slug = mainmenu.toLowerCase()+"-"+"our-customers";
  }
  
  let response = await axios.get(url + "/getPage/Internal?slug=" + change_slug);
  return response.data;
};

export const InternalPreviewApi = async (url, slug,mainmenu) => {
  var change_slug = slug;
  if(slug==="OpeningHours"){
    change_slug = mainmenu.toLowerCase()+"-"+"opening-hours";
    
  }else if(slug==="OurCustomers"){
    change_slug = mainmenu+"-"+"our-customers";
  }
  let response = await axios.get(
    url + "/getPage/preview/Internal?slug=" + change_slug
  );
  return response.data;
};
