import axios from "axios";

export const DenmarkApi = async (url) => {
  let response = await axios.get(url + "/getPage/Contact/Denmark");
  return response.data;
};


export const CopenhagenApi = async (url) => {
  let response = await axios.get(url + "/getPage/Contact/Denmark/Copenhagen");
  return response.data;
};


export const BillundApi = async (url) => {
  let response = await axios.get(url + "/getPage/Contact/Denmark/Billund");
  return response.data;
};



export const SwedenApi = async (url) => {
  let response = await axios.get(url + "/getPage/Contact/Sweden");
  return response.data;
};



// export const StockholmApi = async (url) => {
//   let response = await axios.get(url + "/getPage/Contact/Sweden/Stockholm");
//   return response.data;
// };

export const NorwayApi = async (url) => {
  let response = await axios.get(url + "/getPage/Contact/Norway");
  return response.data;
};

export const EquipmentApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/Contact/Norway/Oslo/AircraftHandling/Equipment"
  );
  return response.data;
};


// export const FacilitiesApi = async (url) => {
//   let response = await axios.get(
//     url + "/getPage/Contact/Norway/Oslo/AirCraftHandling/Facilities"
//   );
//   return response.data;
// };
// Preview Api



export const DenmarkPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/Contact/Denmark");
  return response.data;
};
export const CopenhagenPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/Contact/Denmark/Copenhagen");
  return response.data;
};
export const BillundPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/Contact/Denmark/Billund");
  return response.data;
};

export const SwedenPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/Contact/Sweden");
  return response.data;
};

// export const StockholmPreviewApi = async (url) => {
//   let response = await axios.get(url + "/getPage/preview/Contact/Sweden/Stockholm");
//   return response.data;
// };
export const NorwayPreviewApi = async (url) => {
  let response = await axios.get(url + "/getPage/preview/Contact/Norway");
  return response.data;
};
export const EquipmentPreviewApi = async (url) => {
  let response = await axios.get(
    url + "/getPage/preview/Contact/Norway/Oslo/AircraftHandling/Equipment"
  );
  return response.data;
};

// export const FacilitiesPreviewApi = async (url) => {
//   let response = await axios.get(
//     url + "/getPage/preview/Contact/Norway/Oslo/AirCraftHandling/Facilities"
//   );
//   return response.data;
// };