import axios from "axios";

export const searchApi = async (url) => {
  let contentdata = {
    contentType:"mainMenu,contactUsV1,spiritHomePage",
  };
  try {
    let response = await axios.post(
      url + "/getpage/searchapi/search",
      contentdata,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
