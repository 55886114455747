import SubMenu from "./SubMenu";
import "./menu.css";
const SubSubMenu = ({ submenus, dropdown, depthLevel, length }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <>
      {length > 0 ? (
        <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
          {submenus?.map((submenu, index) => (
            <SubMenu items={submenu} key={index} depthLevel={depthLevel} />
          ))}
        </ul>
      ) : (
        ""
      )}
    </>
  );
};

export default SubSubMenu;
