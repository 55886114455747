import React, { Children, useEffect, useState } from "react";
import classes from "../../OurLocations.module.css";
import { InternalApi, InternalPreviewApi } from "../../../../Services/Internal";
import { childApi, childPreviewApi } from "../../../../Services/HeaderService";
import Top from "../../../../utils/Top/Top";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
// import Slider from "../Slider/Slider";
import "../../../../assets/theme/css/main.css";
import { PageTitle } from "../../../../utils/common";

const ArlandaOpeningHours = () => {
  let location = useLocation();
  const Dispatch = useDispatch();
  const url = useSelector((state) => state.url);
  const [internal, setInternal] = useState(null);
  const [slug1, setSlug1] = useState(null);
  const [result1, setResultTable] = useState(null);
  const [result2, setResult] = useState(null);
  let path = [];

  useEffect(async () => {
    let internalData;
    const slug = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    const mainmenu = location.pathname.substring(
      location.pathname.lastIndexOf(
        "/",
        location.pathname.lastIndexOf("/") - 1
      ) + 1,
      location.pathname.lastIndexOf("/")
    );
    setSlug1(slug);
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      internalData = await InternalPreviewApi(url, slug, mainmenu);
    } else {
      internalData = await InternalApi(url, slug, mainmenu);
    }
    PageTitle(internalData?.Title);
    setInternal(internalData);
    console.log(internalData, "data");
  }, []);
  const createData = (terminal, value) => {
    return { terminal, value };
  };
  const createData1 = (value, yes, no) => {
    return { value, yes, no };
  };

  const Menu = (data, res) => {
    let menu = [];
    data?.map(async (value1, id) => {
      let getData;
      const queryParams = new URLSearchParams(location.search);
      const val = queryParams.get("v") || "";
      if (val == "preview") {
        getData = await childPreviewApi(url, value1.sys.id);
      } else {
        getData = await childApi(url, value1.sys.id);
      }
      let value = Object.values(getData);

      menu.push(createData(...value));
      if (menu.length == data.length) {
        res(menu);
      }
    });
  };
  const Menu1 = (data, res) => {
    let menu = [];
    data?.map(async (value1, id) => {
      let getData;
      const queryParams = new URLSearchParams(location.search);
      const val = queryParams.get("v") || "";
      if (val == "preview") {
        getData = await childPreviewApi(url, value1.sys.id);
      } else {
        getData = await childApi(url, value1.sys.id);
      }
      let value = Object.values(getData);

      menu.push(createData1(...value));
      if (menu.length == data.length) {
        res(menu);
      }
    });
  };

  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-6">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-7">
      {children}
    </h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        if (
          node.data.target.sys.id == "6FvO1TyAQwfouFtAjTED3G" ||
          node.data.target.sys.id == "1vqomCCKM8LiY3cYnyfINQ"
        ) {
          return (
            <NavLink
              to={{
                pathname: "/contact-us/denmark",
                aboutProps: {
                  id: node.data.target.sys.id,
                },
              }}
              style={{ textDecoration: "none" }}
            >
              {children}
            </NavLink>
          );
        } else {
          return (
            <NavLink
              to={{
                pathname:
                  (slug1 === "Copenhagen"
                    ? "/locations/Denmark/Copenhagen/"
                    : slug1 === "Billund"
                    ? "/locations/Denmark/Billund/"
                    : "/locations/") + node.data.target.fields.slug,
                aboutProps: {
                  id: node.data.target.sys.id,
                },
              }}
              style={{ textDecoration: "none" }}
            >
              {children}
            </NavLink>
          );
        }
      },

      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.target?.fields?.file?.url}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.uri}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }

  let display = null;

  if (internal) {
    display = internal?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = internal?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }

  return (
    <>
      {internal?.PageImages?.length > 0 && (
        <Top
          pageTitle={internal?.PageTitle}
          images={internal?.PageImages}
          title={internal?.Title}
        />
      )}
      <section
        className={
          classes.sectionContent +
          "   text-left content5 centeradd cid-szWdSGiEXu"
        }
      >
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={internal?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = internal?.Path.substring(
                      0,
                      internal?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {internal?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {internal?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>

          {internal?.PageCards && (
            <Row className="mt-4">
              {internal?.PageCards?.map((info1, id2) => {
                return (
                  <Col>
                    <Card className="text-center">
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          image={"https:" + info1.fields.image.fields.file.url}
                        />
                        <CardContent sx={{ minHeight: 150 }}>
                          {id2 == 0 ? (
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="div"
                              className="card-title"
                            >
                              <Link
                                to={{
                                  pathname: "/locations/Denmark/Services",
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                {info1.fields.title}
                              </Link>
                            </Typography>
                          ) : (
                            ""
                          )}
                          {id2 == 1 ? (
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="div"
                              className="card-title"
                            >
                              <Link
                                to={{
                                  pathname:
                                    "/locations/Denmark/" +
                                    slug1 +
                                    "/" +
                                    info1.fields.title,
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                {info1.fields.title}
                              </Link>
                            </Typography>
                          ) : (
                            ""
                          )}
                          {id2 == 2 ? (
                            <Typography
                              gutterBottom
                              variant="h4"
                              component="div"
                              className="card-title"
                            >
                              <Link
                                to={{
                                  pathname: "/contact-us/denmark",
                                }}
                                style={{ textDecoration: "none" }}
                              >
                                {info1.fields.title}
                              </Link>
                            </Typography>
                          ) : (
                            ""
                          )}

                          <Typography className="mbr-black mbr-fonts-style mbr-text mb-3 display-7 text-center card-desc">
                            {info1.fields.description?.content?.map(
                              (info, id) => {
                                return documentToReactComponents(info);
                              }
                            )}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </Container>
      </section>
      {internal?.OtherImages && (
        <section className="content7 cid-szWpH3l81p">
          <Container>
            {internal?.OtherImages && (
              <Row className="mt-4">
                {internal?.OtherImages?.map((info1, id2) => {
                  return (
                    <>
                      <Col sm={4} className="text-center">
                        <img
                          className={classes.sliderImage}
                          src={"https:" + info1?.fields?.file?.url}
                        />
                        <h4>{info1?.fields?.title}</h4>
                      </Col>
                    </>
                  );
                })}
              </Row>
            )}
          </Container>
        </section>
      )}
    </>
  );
};

export default ArlandaOpeningHours;
