import React, { useEffect, useState } from "react";
import classes from "./Footer.module.css";
import { footerApi, footerPreviewApi } from "../../Services/FooterService";
import { Row, Col, Container } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [footer, setFooter] = useState(null);
  useEffect(async () => {
    let footerData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      footerData = await footerPreviewApi(url);
    } else {
      footerData = await footerApi(url);
    }
    setFooter(footerData);
  }, []);
  let display = null;

  if (footer) {
    display = footer?.FooterLinks?.map((info, id) => {
      return (
        <>
          <li className="foot-menu-item mbr-fonts-style display-7">
            <NavLink
              className={classes.FooterLink}
              to={{
                pathname:
                  info.fields.slug != "/" ? "/" + info.fields.slug : "/",
                aboutProps: {
                  id:
                    info.fields.slug != "/"
                      ? info.sys.id
                      : "372R22W0nmXiUdqrWOYBf1",
                },
              }}
            >
              {info.fields.title}
            </NavLink>
          </li>
        </>
      );
    });
  }
  return (
    <>
      <section
        className="footer3 cid-szWe9F6u0t"
        once="footers"
        style={{ backgroundColor: "rgb(223 222 221) !important" }}
      >
        <Container fluid>
          <Row>
            <Col className="footer-row px-5 align-center mbr-white">
              <ul className="foot-menu">
                {display}
                <li className="foot-menu-item mbr-fonts-style display-7">
                  <NavLink
                    className={classes.FooterLink}
                    to={{
                      pathname: "/search",
                    }}
                  >
                    Search
                  </NavLink>
                </li>
              </ul>
              {/* <div className={classes.upperFooter}>{displayUpper}</div> */}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="cid-szWpH3l81p">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <p className="mbr-fonts-style mbr-text mbr-black mb-0 display-7">
                {footer?.Copyright}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );

  // return (
  //   <div className={classes.footer + " mt-auto"}>
  //     <div>
  //       <hr />
  //     </div>
  //     <div className={classes.upperFooter}>{displayUpper}</div>
  //     <div className={classes.lowerFooter}>
  //       <div>{Copyright}</div>
  //     </div>
  //   </div>
  // );
};

export default Footer;
