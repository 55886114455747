import "./App.css";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import { Switch, Route } from "react-router-dom";
import SghTraining from "./Components/OurServices/pages/SghTraining/SghTraining";
import OurLocations from "./Components/OurLocations/OurLocations";
import ContactUs from "./Components/Contact/Contact";
import DenmarkContact from "./Components/Contact/pages/Denmark/Denmark";
import NorwayContact from "./Components/Contact/pages/Norway/Norway";
import SwedenContact from "./Components/Contact/pages/Sweden/Sweden";
import Denmark from "./Components/OurLocations/pages/Denmark/Denmark";
import Norway from "./Components/OurLocations/pages/Norway/Norway";
import Sweden from "./Components/OurLocations/pages/Sweden/Sweden";
import NewTracking from "./Components/Awb/NewTracking";
import Tracking from "./Components/Awb/Tracking";
import Equipment from "./Components/Contact/pages/Norway/Equipment";
import RegistrationForm from "./Components/RegistrationForm/RegistrationForm";
import DenmarkServices from "./Components/OurLocations/pages/Denmark/DenmarkServices";
import CopenhagenHandlingAirlines from "./Components/OurLocations/pages/Denmark/CopenhagenHandlingAirlines";
import CopenhagenHandlingTimes from "./Components/OurLocations/pages/Denmark/CopenhagenHandlingTimes";
import CopenhagenLocation from "./Components/OurLocations/pages/Denmark/CopenhagenLocation";
import CopenhagenFacilities from "./Components/OurLocations/pages/Denmark/CopenhagenFacilities";
import BillundFacilities from "./Components/OurLocations/pages/Denmark/BillundFacilities";
import Copenhagen from "./Components/OurLocations/pages/Denmark/Copenhagen";
import Billund from "./Components/OurLocations/pages/Denmark/Billund";
import Oslo from "./Components/OurLocations/pages/Norway/Oslo";
import Arlanda from "./Components/OurLocations/pages/Sweden/Arlanda";
import OsloOpeningHours from "./Components/OurLocations/pages/Norway/OsloOpeningHours";
import OsloOurCustomers from "./Components/OurLocations/pages/Norway/OsloOurCustomers";
import ArlandaOpeningHours from "./Components/OurLocations/pages/Sweden/ArlandaOpeningHours";
import ArlandaOurCustomers from "./Components/OurLocations/pages/Sweden/ArlandaOurCustomers";
import BillundLocation from "./Components/OurLocations/pages/Denmark/BillundLocation";
import BillundHandlingTimes from "./Components/OurLocations/pages/Denmark/BillundHandlingTimes";
import StockholmServices from "./Components/OurLocations/pages/Sweden/StockholmServices";
import PUCPC from "./Components/OurLocations/pages/Sweden/PUCPC";
import StockholmFacilities from "./Components/OurLocations/pages/Sweden/StockholmFacilities";
import StockholmHandlingAirlines from "./Components/OurLocations/pages/Sweden/StockholmHandlingAirlines";
import GoodExpectedReady from "./Components/OurLocations/pages/Denmark/GoodsExpectedReady";
import Search from "./Components/Search/Search";
import Internal from "./Components/Internal/Internal";
import Certificates from "./Components/About/pages/Certificates";
import Quality from "./Components/About/pages/Quality";
import Environment from "./Components/About/pages/Environment";
import Sustainability from "./Components/About/pages/Sustainability";
import Pharma from "./Components/About/pages/Pharma";
import SafetyAndSecurity from "./Components/About/pages/SafetyAndSecurity";
import About from "./Components/About/About";
import WareHouseHandling from "./Components/Services/pages/WareHouseHandling";
import CargoRampHandling from "./Components/Services/pages/CargoRampHandling";
import OtherWareHouseService from "./Components/Services/pages/OtherWareHouseService";
import Services from "./Components/Services/Services";
import OpeningHours from "./Components/OurLocations/pages/Denmark/OpeningHours";
import OurCustomers from "./Components/OurLocations/pages/Denmark/OurCustomers";
import BillundOurCustomers from "./Components/OurLocations/pages/Denmark/BillundOurCustomers";
import BillundOpeningHours from "./Components/OurLocations/pages/Denmark/BillundOpeningHours";
import OsloFacilities from "./Components/OurLocations/pages/Norway/OsloFacilities";
const App = () => {
  return (
    <>
      <Header />
      <div className="main-wrapper">
        <Switch>
          <Route path="/track-data" exact component={Tracking}></Route>
          <Route path="/tracking" exact component={Home}></Route>
          <Route
            path="/locations/norway/oslo/aircraft-handling/equipment"
            exact
            component={Equipment}
          ></Route>
          <Route path="/services" exact component={Services}></Route>
          <Route path="/about-us" exact component={About}></Route>
          <Route
            path="/about-us/safety-and-security"
            component={SafetyAndSecurity}
          />
          <Route path="/about-us/pharma" component={Pharma} />
          <Route path="/about-us/sustainability" component={Sustainability} />
          <Route path="/about-us/certificates" component={Certificates} />
          <Route path="/about-us/quality" exact component={Quality} />
          <Route path="/about-us/environment" exact component={Environment} />
          <Route path="/locations" exact component={OurLocations} />
          <Route path="/locations/Denmark" exact component={Denmark} />
          <Route
            path="/locations/Denmark/Services"
            exact
            component={DenmarkServices}
          />
          <Route
            path="/locations/Denmark/Copenhagen/AirlinesHandeledCopenhagen"
            exact
            component={CopenhagenHandlingAirlines}
          />
          <Route
            path="/locations/Denmark/Copenhagen/HandlingTimes"
            exact
            component={CopenhagenHandlingTimes}
          />
          <Route
            path="/locations/Denmark/Copenhagen/OpeningHours"
            exact
            component={OpeningHours}
          />
          <Route
            path="/locations/Denmark/Copenhagen/OurCustomers"
            exact
            component={OurCustomers}
          />
          <Route
            path="/locations/Denmark/Billund/OpeningHours"
            exact
            component={BillundOpeningHours}
          />
          <Route
            path="/locations/Denmark/Billund/OurCustomers"
            exact
            component={BillundOurCustomers}
          />
          <Route
            path="/locations/Sweden/Arlanda/OpeningHours"
            exact
            component={ArlandaOpeningHours}
          />
          <Route
            path="/locations/Sweden/Arlanda/OurCustomers"
            exact
            component={ArlandaOurCustomers}
          />
          <Route
            path="/locations/Denmark/Copenhagen"
            exact
            component={Copenhagen}
          />
          <Route path="/locations/Denmark/Billund" exact component={Billund} />
          <Route path="/locations/Sweden/Arlanda" exact component={Arlanda} />
          <Route
            path="/locations/Denmark/Copenhagen/Location"
            exact
            component={CopenhagenLocation}
          />
          <Route
            path="/locations/Denmark/Copenhagen/Facilities"
            exact
            component={CopenhagenFacilities}
          />
          <Route
            path="/locations/Denmark/Billund/Facilities"
            exact
            component={BillundFacilities}
          />
          <Route
            path="/locations/Denmark/Billund/Location"
            exact
            component={BillundLocation}
          />
          <Route
            path="/locations/Denmark/Billund/GoodsExpectedReady"
            exact
            component={GoodExpectedReady}
          />
          <Route
            path="/locations/Denmark/Billund/HandlingTimesBillund"
            exact
            component={BillundHandlingTimes}
          />
          <Route path="/locations/Norway" exact component={Norway} />
          <Route path="/locations/Norway/Oslo" exact component={Oslo} />
          <Route
            path="/locations/Norway/Oslo/Facilities"
            exact
            component={OsloFacilities}
          />
          <Route
            path="/locations/Norway/Oslo/OurCustomers"
            exact
            component={OsloOurCustomers}
          />
          <Route
            path="/locations/Norway/Oslo/OpeningHours"
            exact
            component={OsloOpeningHours}
          />
          {/* <Route
            path="/locations/Norway/Oslo/AircraftHandling"
            exact
            component={Internal}
          />
         */}
          <Route path="/locations/Sweden" exact component={Sweden} />
          <Route path="/locations/Sweden/PUCPC" exact component={PUCPC} />
          <Route
            path="/locations/Sweden/Arlanda/Services"
            exact
            component={StockholmServices}
          />
          <Route
            path="/locations/Sweden/Arlanda/Facilities"
            exact
            component={StockholmFacilities}
          />
          <Route
            path="/locations/Sweden/Arlanda/HandlingAirlines"
            exact
            component={StockholmHandlingAirlines}
          />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/contact-us/denmark" exact component={DenmarkContact} />
          <Route path="/contact-us/norway" exact component={NorwayContact} />
          <Route path="/contact-us/sweden" exact component={SwedenContact} />

          <Route path="/services/sgh-training" exact component={SghTraining} />
          <Route
            path="/services/warehouse-handling"
            exact
            component={WareHouseHandling}
          />
          <Route
            path="/services/cargo-ramp-handling"
            exact
            component={CargoRampHandling}
          />
          <Route
            path="/services/other-warehouse-services"
            exact
            component={OtherWareHouseService}
          ></Route>
          <Route path="/registration-form" exact component={RegistrationForm} />
          <Route path="/" exact component={Home} />
          <Route path="/search" exact component={Search} />
        </Switch>
      </div>

      <Footer />
    </>
  );
};

export default App;
