import axios from 'axios';

export const footerApi = async (url) => {
    let response = await axios.get(url+"/getPage/Footer");
    return response.data;
}

export const footerPreviewApi = async (url) => {
    let response = await axios.get(url+"/getPage/preview/Footer");
    return response.data;
}