import React, { useEffect, useState } from "react";
import {
  DenmarkApi,
  DenmarkPreviewApi,
} from "../../../../Services/OurLocationChildService";
import { childApi, childPreviewApi } from "../../../../Services/HeaderService";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
import classes from "../../OurLocations.module.css";
import TopNew from "../../../../utils/Top/Top-new";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { useLocation, Link, NavLink } from "react-router-dom";
import { PageTitle } from "../../../../utils/common";

const Denmark = (props) => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [expanded, setExpanded] = React.useState("address1");
  const [denmark, setDenmark] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [apiTitle, setApiTitle] = useState(null);
  const handleChange = (address) => async (event, newExpanded) => {
    setExpanded(newExpanded ? address : false);
  };
  let path = [];
  const handleLink = (id, url) => {
    props.history.push({
      pathname: url,
      state: { id: id },
    });
  };

  useEffect(async () => {
    let denmarkData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      denmarkData = await DenmarkPreviewApi(url);
    } else {
      denmarkData = await DenmarkApi(url);
    }
    PageTitle(denmarkData?.Title ?? "");
    setDenmark(denmarkData);
  }, []);

  const apiCall = async (id, title) => {
    let data;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      data = await childPreviewApi(url, id);
    } else {
      data = await childApi(url, id);
    }
    setApiData(data);
    if (title === "Copenhagen" || title === "Billund") setApiTitle(title);
    else setApiTitle(null);
  };
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-6">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3  display-7">{children}</h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        console.log("node : ", node);
        if (
          node.data.target.sys.id == "6FvO1TyAQwfouFtAjTED3G" ||
          node.data.target.sys.id == "1vqomCCKM8LiY3cYnyfINQ"
        ) {
          return (
            <NavLink
              to={{
                pathname: "/contact-us/denmark",
                aboutProps: {
                  id: node.data.target.sys.id,
                },
              }}
              style={{ textDecoration: "none" }}
            >
              {children}
            </NavLink>
          );
        } else {
          return (
            <NavLink
              to={{
                pathname:
                  (apiTitle === "Copenhagen"
                    ? "/locations/Denmark/Copenhagen/"
                    : apiTitle === "Billund"
                      ? "/locations/Denmark/Billund/"
                      : "/locations/") + node.data.target.fields.slug,
                aboutProps: {
                  id: node.data.target.sys.id,
                },
              }}
              style={{ textDecoration: "none" }}
            >
              {children}
            </NavLink>
          );
        }
      },
      // [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      //   console.log(node.data);
      //   if (node.data.target.sys.id == "7aMBupUEgF020Y5wXWbufF") {
      //     return (
      //       <a
      //         onClick={() =>
      //           handleLink(
      //             node.data.target.sys.id,
      //             "/HandlingAirlines"
      //           )
      //         }
      //         style={{ textDecoration: "none" }}
      //       >
      //         {children}
      //       </a>
      //     );
      //   } else if (node.data.target.sys.id == "56DgsPzzHaGpHpI9ry4OrO") {
      //     return (
      //       <a
      //         onClick={() =>
      //           handleLink(
      //             node.data.target.sys.id,
      //             "/locations/Denmark/Copenhagen/HandlingTimes"
      //           )
      //         }
      //         style={{ textDecoration: "none" }}
      //       >
      //         {children}
      //       </a>
      //     );
      //   } else if (node.data.target.sys.id == "6COsGNPVXlhWnFcvdI6Reb") {
      //     return (
      //       <a
      //         onClick={() =>
      //           handleLink(
      //             node.data.target.sys.id,
      //             "/locations/Denmark/Billund/Location"
      //           )
      //         }
      //         style={{ textDecoration: "none" }}
      //       >
      //         {children}
      //       </a>
      //     );
      //   } else if (node.data.target.sys.id == "5ILebsXNe1Y2QnAtIqvu4D") {
      //     return (
      //       <a
      //         onClick={() =>
      //           handleLink(
      //             node.data.target.sys.id,
      //             "/locations/Denmark/Copenhagen/Location"
      //           )
      //         }
      //         style={{ textDecoration: "none" }}
      //       >
      //         {children}
      //       </a>
      //     );
      //   } else if (node.data.target.sys.id == "3uhIyQnjSEB7ZPQwA3zMyG") {
      //     return (
      //       <a
      //         onClick={() =>
      //           handleLink(
      //             node.data.target.sys.id,
      //             "/locations/Denmark/Billund/HandlingTimesBillund"
      //           )
      //         }
      //         style={{ textDecoration: "none" }}
      //       >
      //         {children}
      //       </a>
      //     );
      //   } else {
      //   }
      // },

      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.target?.fields?.file?.url}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node?.data?.uri}`}
            target="_blank"
            download
            style={{ textDecoration: "none" }}
          >
            {children}
          </a>
        );
      },
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  let display,
    news = null;

  if (denmark) {
    display = denmark?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    news = denmark?.News?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = denmark?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }

  return (
    <>
      {/* <Slider data={denmark?.PageImages} title={denmark?.Title}></Slider> */}
      {denmark?.PageImages?.length > 0 && (
        <TopNew
          pageTitle={denmark?.PageTitle}
          images={denmark?.PageImages}
          title={denmark?.Title}
        />
      )}
      <section
        className={
          classes.sectionContent +
          " text-left content5 centeradd cid-szWdSGiEXu"
        }
      >
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={denmark?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = denmark?.Path.substring(
                      0,
                      denmark?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {denmark?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {denmark?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>

          {/* <Row>
            <Col md={12}>
              {denmark?.SubMenu?.map((info, id) => {
                return (
                  <Accordion
                    expanded={expanded === info.fields.title}
                    onChange={handleChange(info.fields.title)}
                  >
                    <AccordionSummary
                      id={info.fields.title + "d-header"}
                      onClick={() => {
                        apiCall(info.sys.id, info.fields.title);
                      }}
                    >
                      <Typography>{info.fields.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {apiData && (
                        <Row>
                          <Col>
                            {apiData?.description?.content?.map((info, id) => {
                              return documentToReactComponents(info, options);
                            })}
                          </Col>
                        </Row>
                      )}
                      {apiData?.pageImages && (
                        <Row className="mt-4">
                          {apiData?.pageImages?.map((info1, id2) => {
                            return (
                              <Col>
                                <Card className="text-center">
                                  <CardActionArea>
                                    <CardMedia
                                      component="img"
                                      image={"https:" + info1.fields.file.url}
                                    />
                                    <CardContent sx={{ minHeight: 150 }}>
                                      {id2 == 0 ? (
                                        <Typography
                                          gutterBottom
                                          variant="h4"
                                          component="div"
                                          className="card-title"
                                        >
                                          <Link
                                            to={{
                                              pathname:
                                                "/locations/Denmark/Services",
                                            }}
                                            style={{ textDecoration: "none" }}
                                          >
                                            {info1.fields.title}
                                          </Link>
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                      {id2 == 1 ? (
                                        <Typography
                                          gutterBottom
                                          variant="h4"
                                          component="div"
                                          className="card-title"
                                        >
                                          <Link
                                            to={{
                                              pathname:
                                                "/locations/Denmark/" +
                                                info.fields.title +
                                                "/" +
                                                info1.fields.title,
                                            }}
                                            style={{ textDecoration: "none" }}
                                          >
                                            {info1.fields.title}
                                          </Link>
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                      {id2 == 2 ? (
                                        <Typography
                                          gutterBottom
                                          variant="h4"
                                          component="div"
                                          className="card-title"
                                        >
                                          <Link
                                            to={{
                                              pathname: "/contact-us/denmark",
                                            }}
                                            style={{ textDecoration: "none" }}
                                          >
                                            {info1.fields.title}
                                          </Link>
                                        </Typography>
                                      ) : (
                                        ""
                                      )}

                                      <Typography className="mbr-black mbr-fonts-style mbr-text mb-3 display-7 text-center card-desc">
                                        {info1.fields.description}
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Col>
          </Row> */}
          <Row className="mb-5">

            {denmark?.links?.map((info, id) => {
              console.log("denmark info: "+info);
              return (
                <Col md="6">
                  <Card className="text-center">
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        sx={{ minHeight: 445 }}
                        // image={"https:" + info.fields.image.fields.file.url}
                        image={"https:" + info?.fields?.image?.fields?.file?.url}
                      />
                      <CardContent sx={{ minHeight: 200 }}>
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          className="card-title"
                        >
                          {info.fields.title}
                        </Typography>
                        <Typography className="mbr-black mbr-fonts-style mbr-text mb-3 display-7 text-center card-desc">
                          {info.fields.description?.content?.map((info, id) => {
                            return documentToReactComponents(info);
                          })}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      {news ? (
        <section className="content7 cid-szWpH3l81p">
          <Container>
            <Row>
              <Col md={12}>
                <blockquote>{news}</blockquote>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
          ""
        )}
    </>
  );
};

export default Denmark;
