import axios from 'axios';

export const headerApi = async (url) => {
    let response = await axios.get(url+"/getPage/Header");
    return response.data;
}

export const quickTrackApi = async (url) => {
    let response = await axios.get(url+"/getPage/QuickTrack");
    return response.data;
}

export const childApi = async (url,id) => {
    let response = await axios.get(url+"/getPage/fetch/"+id);
    return response.data; 
}

export const headerPreviewApi = async (url) => {
    let response = await axios.get(url+"/getPage/preview/Header");
    return response.data;
}

export const childPreviewApi = async (url,id) => {
    let response = await axios.get(url+"/getPage/preview/fetch/"+id);
    return response.data; 
}