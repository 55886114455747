import React, { useState, useEffect } from "react";
import { Drawer, IconButton, makeStyles } from "@material-ui/core";
import { childApi, childPreviewApi } from "../../Services/HeaderService";
import List from "@mui/material/List";
import { Divider, ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { menuItems } from "../NavbarItem/menuItems";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuIcon from "@material-ui/icons/Menu";
import "../../assets/theme/css/main.css";
import SubDrawer from "./SubDrawer";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "blue",
    fontSize: "20px",
  },

  icon: {
    color: "white",
  },
}));

function DrawerComponent(props) {
  let location = useLocation();
  const classes = useStyles();
  const url = useSelector((state) => state.url);
  const [openIds, setOpenIds] = useState([]);
  const handleClick = (listId) => {
    if (!openIds.includes(listId))
      setOpenIds((openIds) => {
        return [...openIds, listId];
      });
    else {
      setOpenIds((openIds) => openIds.filter((getId) => getId != listId));
    }
    // console.log(ids);
    // else setOpenId([]);
  };
  const [openDrawer, setOpenDrawer] = useState(false);

  // const Menu = (subMenu, res) => {
  //   let menu = [];
  //   subMenu?.map(async (data, id) => {
  //     let dropdown;
  //     const queryParams = new URLSearchParams(location.search);
  //     const val = queryParams.get("v") || "";
  //     if (val == "preview") {
  //       dropdown = await childPreviewApi(url, data.sys.id);
  //     } else {
  //       dropdown = await childApi(url, data.sys.id);
  //     }
  //     menu.push({
  //       title: dropdown.title,
  //       slug: dropdown.slug,
  //       id: data.sys.id,
  //     });
  //     menu = menu.sort((a, b) => (a.title > b.title ? 1 : -1));
  //     if (menu.length == subMenu.length) {
  //       res(menu);
  //     }
  //   });
  // };

  // useEffect(async () => {
  //   let dataarr = [];
  //   await props.Menus?.map(async (info, id) => {
  //     await Menu(info.fields.subMenu, (res) => {
  //       dataarr[info.sys.id] = res;
  //       if (id + 1 == props.Menus.length) {
  //         setMenusData(dataarr);
  //       }
  //     });
  //   });
  // }, []);
  if (openIds) console.log(openIds);

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List sx={{ bgcolor: "background.paper" }} component="nav">
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            if (index === 0) {
              return (
                <>
                  <ListItem
                    key={"372R22W0nmXiUdqrWOYBf1"}
                    button
                    onClick={() => handleClick("372R22W0nmXiUdqrWOYBf1")}
                  >
                    <ListItemText>
                      <Link
                        className={classes.link}
                        to={{
                          pathname: "/",
                          aboutProps: {
                            id: "372R22W0nmXiUdqrWOYBf1",
                          },
                        }}
                      >
                        Home
                      </Link>
                    </ListItemText>
                    {openIds.includes("372R22W0nmXiUdqrWOYBf1") ? (
                      <ExpandLess style={{ fill: "#010099" }} />
                    ) : (
                      <ExpandMore style={{ fill: "#010099" }} />
                    )}
                  </ListItem>
                  <Divider />
                  <Collapse
                    in={openIds.includes("372R22W0nmXiUdqrWOYBf1")}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding sx={{ pl: 2 }}>
                      <ListItem
                        key={"2pIom106GJVh9P05UujXSL"}
                        button
                        onClick={() => handleClick("2pIom106GJVh9P05UujXSL")}
                      >
                        <ListItemText>
                          <Link
                            className={classes.link}
                            to={{
                              pathname: "/locations",
                              aboutProps: {
                                id: "2pIom106GJVh9P05UujXSL",
                              },
                            }}
                          >
                            Locations
                          </Link>
                        </ListItemText>
                        {openIds.includes("2pIom106GJVh9P05UujXSL") ? (
                          <ExpandLess style={{ fill: "#010099" }} />
                        ) : (
                          <ExpandMore style={{ fill: "#010099" }} />
                        )}
                      </ListItem>
                      <Divider />
                      <Collapse
                        in={openIds.includes("2pIom106GJVh9P05UujXSL")}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding sx={{ pl: 2 }}>
                          <ListItem
                            key={"5j8wOfa8EszHk6tBXDtSKW"}
                            button
                            onClick={() =>
                              handleClick("5j8wOfa8EszHk6tBXDtSKW")
                            }
                          >
                            <ListItemText>
                              <Link
                                className={classes.link}
                                to={{
                                  pathname: "/locations/Denmark",
                                  aboutProps: {
                                    id: "5j8wOfa8EszHk6tBXDtSKW",
                                  },
                                }}
                              >
                                Denmark
                              </Link>
                            </ListItemText>
                            {openIds.includes("2pIom106GJVh9P05UujXSL") ? (
                              <ExpandLess style={{ fill: "#010099" }} />
                            ) : (
                              <ExpandMore style={{ fill: "#010099" }} />
                            )}
                          </ListItem>
                          <Divider />
                          <Collapse
                            in={openIds.includes("5j8wOfa8EszHk6tBXDtSKW")}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding sx={{ pl: 2 }}>
                              <ListItem
                                key={"3ivwGSAOhDBkTibuZ9XKcH"}
                                button
                                onClick={() =>
                                  handleClick("3ivwGSAOhDBkTibuZ9XKcH")
                                }
                              >
                                <ListItemText>
                                  <Link
                                    className={classes.link}
                                    to={{
                                      pathname: "/locations/Denmark/Copenhagen",
                                      aboutProps: {
                                        id: "3ivwGSAOhDBkTibuZ9XKcH",
                                      },
                                    }}
                                  >
                                    Copenhagen
                                  </Link>
                                </ListItemText>
                                {openIds.includes("3ivwGSAOhDBkTibuZ9XKcH") ? (
                                  <ExpandLess style={{ fill: "#010099" }} />
                                ) : (
                                  <ExpandMore style={{ fill: "#010099" }} />
                                )}
                              </ListItem>
                              <Divider />
                              <Collapse
                                in={openIds.includes("3ivwGSAOhDBkTibuZ9XKcH")}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List
                                  component="div"
                                  disablePadding
                                  sx={{ pl: 2 }}
                                >
                                  <ListItem
                                    key={"58eLQz1BZ59r9y2pqV9Bfy"}
                                    button
                                    onClick={() =>
                                      handleClick("58eLQz1BZ59r9y2pqV9Bfy")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Denmark/Copenhagen/Facilities",
                                          aboutProps: {
                                            id: "58eLQz1BZ59r9y2pqV9Bfy",
                                          },
                                        }}
                                      >
                                        Facilities
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider />

                                  <ListItem
                                    key={"3EgCqTwx81vgntmj2pqypZ"}
                                    button
                                    onClick={() =>
                                      handleClick("3EgCqTwx81vgntmj2pqypZ")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Denmark/Copenhagen/OpeningHours",
                                          aboutProps: {
                                            id: "3EgCqTwx81vgntmj2pqypZ",
                                          },
                                        }}
                                      >
                                        Opening Hours
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider />
                                  <ListItem
                                    key={"irN5e3U4lpwaaWZSj5fJJ"}
                                    button
                                    onClick={() =>
                                      handleClick("irN5e3U4lpwaaWZSj5fJJ")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Denmark/Copenhagen/OurCustomers",
                                          aboutProps: {
                                            id: "irN5e3U4lpwaaWZSj5fJJ",
                                          },
                                        }}
                                      >
                                        Our Customers
                                      </Link>
                                    </ListItemText>
                                  </ListItem>

                                  <Divider />
                                </List>
                              </Collapse>
                              <Divider />
                              <ListItem
                                key={"x7dBHLv6f2A5xecp3XBND"}
                                button
                                onClick={() =>
                                  handleClick("x7dBHLv6f2A5xecp3XBND")
                                }
                              >
                                <ListItemText>
                                  <Link
                                    className={classes.link}
                                    to={{
                                      pathname: "/locations/Denmark/Billund",
                                      aboutProps: {
                                        id: "x7dBHLv6f2A5xecp3XBND",
                                      },
                                    }}
                                  >
                                    Billund
                                  </Link>
                                </ListItemText>
                                {openIds.includes("x7dBHLv6f2A5xecp3XBND") ? (
                                  <ExpandLess style={{ fill: "#010099" }} />
                                ) : (
                                  <ExpandMore style={{ fill: "#010099" }} />
                                )}
                              </ListItem>
                              <Divider />
                              <Collapse
                                in={openIds.includes("x7dBHLv6f2A5xecp3XBND")}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List
                                  component="div"
                                  disablePadding
                                  sx={{ pl: 2 }}
                                >
                                  <ListItem
                                    key={"5niiiXfmOzCDtPaaxwz5va"}
                                    button
                                    onClick={() =>
                                      handleClick("5niiiXfmOzCDtPaaxwz5va")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Denmark/Billund/Facilities",
                                          aboutProps: {
                                            id: "5niiiXfmOzCDtPaaxwz5va",
                                          },
                                        }}
                                      >
                                        Facilities
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider />

                                  <ListItem
                                    key={"SsOUi7Jvq1SgT663ccC82"}
                                    button
                                    onClick={() =>
                                      handleClick("SsOUi7Jvq1SgT663ccC82")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Denmark/Billund/OpeningHours",
                                          aboutProps: {
                                            id: "SsOUi7Jvq1SgT663ccC82",
                                          },
                                        }}
                                      >
                                        Opening Hours
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider />
                                  <ListItem
                                    key={"6fmE05HI4i67Zb2SUELCIO"}
                                    button
                                    onClick={() =>
                                      handleClick("6fmE05HI4i67Zb2SUELCIO")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Denmark/Billund/OurCustomers",
                                          aboutProps: {
                                            id: "6fmE05HI4i67Zb2SUELCIO",
                                          },
                                        }}
                                      >
                                        Our Customers
                                      </Link>
                                    </ListItemText>
                                  </ListItem>

                                  <Divider />
                                </List>
                              </Collapse>
                              <Divider />
                            </List>
                          </Collapse>
                          <Divider />
                          <ListItem
                            key={"2Vc3sULtlFHpZaCrKG1DP6"}
                            button
                            onClick={() =>
                              handleClick("2Vc3sULtlFHpZaCrKG1DP6")
                            }
                          >
                            <ListItemText>
                              <Link
                                className={classes.link}
                                to={{
                                  pathname: "/locations/Norway",
                                  aboutProps: {
                                    id: "2Vc3sULtlFHpZaCrKG1DP6",
                                  },
                                }}
                              >
                                Norway
                              </Link>
                            </ListItemText>
                            {openIds.includes("2Vc3sULtlFHpZaCrKG1DP6") ? (
                              <ExpandLess style={{ fill: "#010099" }} />
                            ) : (
                              <ExpandMore style={{ fill: "#010099" }} />
                            )}
                          </ListItem>
                          <Divider />
                          <Collapse
                            in={openIds.includes("2Vc3sULtlFHpZaCrKG1DP6")}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding sx={{ pl: 2 }}>
                              <ListItem
                                key={"3Hecckym3sXYVg3M1dNx6o"}
                                button
                                onClick={() =>
                                  handleClick("3Hecckym3sXYVg3M1dNx6o")
                                }
                              >
                                <ListItemText>
                                  <Link
                                    className={classes.link}
                                    to={{
                                      pathname: "/locations/Norway/Oslo",
                                      aboutProps: {
                                        id: "3Hecckym3sXYVg3M1dNx6o",
                                      },
                                    }}
                                  >
                                    Oslo
                                  </Link>
                                </ListItemText>
                                {openIds.includes("3Hecckym3sXYVg3M1dNx6o") ? (
                                  <ExpandLess style={{ fill: "#010099" }} />
                                ) : (
                                  <ExpandMore style={{ fill: "#010099" }} />
                                )}
                              </ListItem>
                              <Divider/>
                              <Collapse
                                in={openIds.includes("3Hecckym3sXYVg3M1dNx6o")}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List
                                  component="div"
                                  disablePadding
                                  sx={{ pl: 2 }}
                                >
                                  <ListItem
                                    key={"5QfjCs2rvkxAMYPPmBhREE"}
                                    button
                                    onClick={() =>
                                      handleClick("5QfjCs2rvkxAMYPPmBhREE")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Norway/Oslo/Facilities",
                                          aboutProps: {
                                            id: "5QfjCs2rvkxAMYPPmBhREE",
                                          },
                                        }}
                                      >
                                        Facilities
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider/>

                                  <ListItem
                                    key={"SsOUi7Jvq1SgT663ccC82"}
                                    button
                                    onClick={() =>
                                      handleClick("SsOUi7Jvq1SgT663ccC82")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Norway/Oslo/OpeningHours",
                                          aboutProps: {
                                            id: "SsOUi7Jvq1SgT663ccC82",
                                          },
                                        }}
                                      >
                                        Opening Hours
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider/>
                                  <ListItem
                                    key={"6fmE05HI4i67Zb2SUELCIO"}
                                    button
                                    onClick={() =>
                                      handleClick("6fmE05HI4i67Zb2SUELCIO")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Norway/Oslo/OurCustomers",
                                          aboutProps: {
                                            id: "6fmE05HI4i67Zb2SUELCIO",
                                          },
                                        }}
                                      >
                                        Our Customers
                                      </Link>
                                    </ListItemText>
                                  </ListItem>

                                  <Divider />
                                </List>
                              </Collapse>
                              <Divider />

                              {/* <Divider /> */}
                            </List>
                          </Collapse>
                          <Divider />
                          <ListItem
                            key={"2MGX3cosDeReLYngGczYmy"}
                            button
                            onClick={() =>
                              handleClick("2MGX3cosDeReLYngGczYmy")
                            }
                          >
                            <ListItemText>
                              <Link
                                className={classes.link}
                                to={{
                                  pathname: "/locations/Sweden",
                                  aboutProps: {
                                    id: "2MGX3cosDeReLYngGczYmy",
                                  },
                                }}
                              >
                                Sweden
                              </Link>
                            </ListItemText>
                            {openIds.includes("2MGX3cosDeReLYngGczYmy") ? (
                              <ExpandLess style={{ fill: "#010099" }} />
                            ) : (
                              <ExpandMore style={{ fill: "#010099" }} />
                            )}
                          </ListItem>
                          <Divider/>
                          <Collapse
                            in={openIds.includes("2MGX3cosDeReLYngGczYmy")}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding sx={{ pl: 2 }}>
                              <ListItem
                                key={"2hazO0LQKfQFwc5ttcLUg8"}
                                button
                                onClick={() =>
                                  handleClick("2hazO0LQKfQFwc5ttcLUg8")
                                }
                              >
                                <ListItemText>
                                  <Link
                                    className={classes.link}
                                    to={{
                                      pathname: "/locations/Sweden/Arlanda",
                                      aboutProps: {
                                        id: "2hazO0LQKfQFwc5ttcLUg8",
                                      },
                                    }}
                                  >
                                    Arlanda
                                  </Link>
                                </ListItemText>
                                {openIds.includes("2hazO0LQKfQFwc5ttcLUg8") ? (
                                  <ExpandLess style={{ fill: "#010099" }} />
                                ) : (
                                  <ExpandMore style={{ fill: "#010099" }} />
                                )}
                              </ListItem>
                              <Divider />
                              <Collapse
                                in={openIds.includes("2hazO0LQKfQFwc5ttcLUg8")}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List
                                  component="div"
                                  disablePadding
                                  sx={{ pl: 2 }}
                                >
                                  <ListItem
                                    key={"4rXCY32zIEz8IBjq55l7OS"}
                                    button
                                    onClick={() =>
                                      handleClick("4rXCY32zIEz8IBjq55l7OS")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Sweden/Arlanda/Facilities",
                                          aboutProps: {
                                            id: "4rXCY32zIEz8IBjq55l7OS",
                                          },
                                        }}
                                      >
                                        Facilities
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider />

                                  <ListItem
                                    key={"5qCDnzIMvdEt844dRMZzaj"}
                                    button
                                    onClick={() =>
                                      handleClick("5qCDnzIMvdEt844dRMZzaj")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Sweden/Arlanda/OpeningHours",
                                          aboutProps: {
                                            id: "5qCDnzIMvdEt844dRMZzaj",
                                          },
                                        }}
                                      >
                                        Opening Hours
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider />
                                  <ListItem
                                    key={"6VluTDjZoej95qVDC56BRu"}
                                    button
                                    onClick={() =>
                                      handleClick("6VluTDjZoej95qVDC56BRu")
                                    }
                                  >
                                    <ListItemText>
                                      <Link
                                        className={classes.link}
                                        to={{
                                          pathname:
                                            "/locations/Sweden/Arlanda/OurCustomers",
                                          aboutProps: {
                                            id: "6VluTDjZoej95qVDC56BRu",
                                          },
                                        }}
                                      >
                                        Our Customers
                                      </Link>
                                    </ListItemText>
                                  </ListItem>
                                  <Divider />
                                </List>
                              </Collapse>
                              <Divider />
                            </List>
                          </Collapse>
                          <Divider />
                        </List>
                      </Collapse>
                      <Divider />
                    </List>
                  </Collapse>
                  <Divider />
                </>
              );
            } else {
              return (
                <SubDrawer items={menu} key={index} depthLevel={depthLevel} />
              );
            }
          })}
        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        className={classes.icon}
      >
        <MenuIcon />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
