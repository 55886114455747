import React, { useState } from "react";
import classes from "./TextForm.module.css";
import TextField from "@mui/material/TextField";

const TextForm = (props) => {
  const [value, setValue] = useState("");

  const handleValue = (event) => {
    setValue(event.target.value);
    props?.value(event.target.value);
    console.log(value.length);
  };

  return (
    <TextField
      // id={props.hasOwnProperty("unique") ? props.unique : ""}
      // error={
      //   props.hasOwnProperty("minlength")
      //     ? val.length !== 0
      //       ? val.length === props.minlength
      //         ? false
      //         : true
      //       : false
      //     : false
      // }
      error={
        value.length == props.maxlength ||
        value.length == 0 ||
        value.length == props.minlength
          ? false
          : true
      }
      label={props?.label}
      type={props.type ? props.type : "text"}
      maxLength={
        props.hasOwnProperty("maxlength")
          ? props.maxlength
            ? props.maxlength
            : ""
          : ""
      }
      autoComplete="current-password"
      variant="filled"
      className={
        props.isEmpty
          ? classes.textform + " " + props?.className
          : classes.empty + " " + props?.className
      }
      inputRef={props.inputRef}
      value={value}
      onChange={handleValue}
    />
  );
};

export default TextForm;
