import { useState } from "react";
import "./NavbarItem.css";
import { Divider, ListItem, makeStyles } from "@material-ui/core";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "blue",
    fontSize: "20px",
  },

  icon: {
    color: "white",
  },
}));
const SubDrawer = ({ items, depthLevel }) => {
  // console.log("sub ",depthLevel);
  const classes = useStyles();
  const [openId, setOpenId] = useState(null);

  const handleClick = (listId) => {
    if (listId != openId) setOpenId(listId);
    else setOpenId(0);
  };

  return items?.submenu ? (
    <>
      <ListItem key={items.id} button onClick={() => handleClick(items.id)}>
        <ListItemText>
          <Link
            className={classes.link}
            to={{
              pathname:
                items.slug != "/"
                  ? items.mainmenu != ""
                    ? "/" + items.mainmenu + "/" + items.slug
                    : "/" + items.slug
                  : "/",
              aboutProps: {
                id: items.slug != "/" ? items.id : "372R22W0nmXiUdqrWOYBf1",
              },
            }}
          >
            {items.title}
          </Link>
        </ListItemText>
        {items.submenu.length > 0 ? (
          openId === items.id ? (
            <ExpandLess style={{ fill: "#010099" }} />
          ) : (
            <ExpandMore style={{ fill: "#010099" }} />
          )
        ) : (
          ""
        )}
      </ListItem>
      <Collapse in={openId == items.id} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 2 }}>
          {items?.submenu?.map((sub, key) => {
            return (
              <>
                <ListItem
                  key={sub.id}
                  button
                  onClick={() => handleClick(sub.id)}
                >
                  <ListItemText>
                    <Link
                      className={classes.link}
                      to={{
                        pathname:
                          sub.slug != "/"
                            ? sub.mainmenu != ""
                              ? "/" + sub.mainmenu + "/" + sub.slug
                              : "/" + sub.slug
                            : "/",
                        aboutProps: {
                          id:
                            sub.slug != "/" ? sub.id : "372R22W0nmXiUdqrWOYBf1",
                        },
                      }}
                    >
                      {sub.title}
                    </Link>
                  </ListItemText>
                </ListItem>

                <Divider />
              </>
            );
          })}
        </List>
      </Collapse>
      <Divider />
    </>
  ) : (
    <a href="/#"> {items?.title} </a>
  );
};
export default SubDrawer;
