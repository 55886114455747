import React, { useEffect, useState } from "react";
import {
  BillundLocationApi,
  BillundLocationPreviewApi,
} from "../../../../Services/OurLocationChildService";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { withRouter } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../../../assets/mobirise/css/mbr-additional.css";
import "../../../../assets/theme/css/style.css";
import classes from "../../OurLocations.module.css";
import Top from "../../../../utils/Top/Top";
import { useLocation, NavLink } from "react-router-dom";
import { PageTitle } from "../../../../utils/common";

const BillundLocation = () => {
  let location = useLocation();
  const url = useSelector((state) => state.url);
  const [data, setData] = useState(null);

  useEffect(async () => {
    let billundLocationData;
    const queryParams = new URLSearchParams(location.search);
    const val = queryParams.get("v") || "";
    if (val == "preview") {
      billundLocationData = await BillundLocationPreviewApi(url);
    } else {
      billundLocationData = await BillundLocationApi(url);
    }
    PageTitle(billundLocationData?.Title ?? "");
    setData(billundLocationData);
  }, []);
  const HEADING_1 = ({ children }) => (
    <h1 className="mbr-section-title text-center mbr-fonts-style mbr-black mb-3 display-5">
      {children}
    </h1>
  );
  const HEADING_2 = ({ node, children }) => (
    <h2 className="mbr-fonts-style mbr-black mb-3 display-6">{children}</h2>
  );
  const HEADING_3 = ({ children }) => (
    <h3 className="mbr-fonts-style mbr-black mb-3 text-center display-7">
      {children}
    </h3>
  );

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={paragraphClass(node)}>{children}</p>
      ),
    },
  };
  function paragraphClass(node) {
    const className = "mbr-fonts-style mbr-text mbr-white mb-3 display-7";
    return className;
  }

  let display = null,
    path = [];

  if (data) {
    display = data?.Description?.content?.map((info, id) => {
      return documentToReactComponents(info, options);
    });
    path = data?.Path.split("/");
    path = path.slice(0, path.length - 1);
  }

  return (
    <>
      {data?.PageImages?.length > 0 && (
        <Top
          pageTitle={data?.PageTitle}
          images={data?.PageImages}
          title={data?.Title}
        />
      )}
      <section
        className={
          classes.sectionContent +
          "   text-left content5 centeradd cid-szWdSGiEXu"
        }
      >
        <Container>
          <Row>
            <Col
              xs="12"
              md="12"
              className={data?.PageImages?.length > 0 ? "" : ""}
            >
              <div className={classes.breadcrumb + " mb-4"}>
                <div>
                  <NavLink to="/">HOME</NavLink>
                  <span className={classes.bread + " me-1 ms-1"}>
                    &gt;
                  </span>{" "}
                  {path?.map((m) => {
                    let pth = data?.Path.substring(
                      0,
                      data?.Path.indexOf(m) + m.length
                    );
                    return (
                      <>
                        <NavLink to={"/" + pth}>
                          {m.replace(/-/g, " ").toUpperCase()}
                        </NavLink>

                        <span className={classes.bread + " me-1 ms-1"}>
                          &gt;
                        </span>
                      </>
                    );
                  })}
                  {data?.Title}
                </div>
              </div>
              <h1 className="mbr-section-title mbr-fonts-style mbr-black mb-3 text-bold display-5">
                {data?.PageTitle}
              </h1>
              {display}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default withRouter(BillundLocation);
