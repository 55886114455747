import axios from "axios";

export const AwbApi = async (url,awb_number) => {
    let response = await axios.post(url+"/Unisys/AWB", {
      awb:awb_number
    }
    );
    return response;
};

export const AwbPreviewApi = async (url,awb_number) => {
  let response = await axios.post(url+"/Preview/Unisys/AWB", {
    awb:awb_number
  }
  );
  return response;
};
